import axios from "axios"

export const PushNotiNode = (URL , Status , Error , method , param)=>{
    console.log("Error==============" , Error);
    
    axios.post("api/noti" , {
        URL,
        Status,
        Error: `${Error}`,
        method,
        param
      })
}