import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { PushNotiNode } from "../utils/notiBot";

function getUrl(config: any) {
  if (config.baseURL) {
    return config.url.replace(config.baseURL, "").split("?")[0];
  }
  return config.url;
}

export const instance = axios.create({
  baseURL: "https://dev-api.tonholding.io/",
  // baseURL: "http://52.194.13.200:4007/",
  headers: {
    Accept: "application/json",
  },
  timeout: 300000,
});

instance.interceptors.request.use(
  async (config: any) => {
    //@ts-ignore
    // console.log(`%c ${config.method.toUpperCase()} - ${config.url}:`, 'color: #0086b3; font-weight: bold', config);
    config.headers["Authorization"] = localStorage.getItem("token") || null;
    return config;
  },
  (error: any) => {
    // console.log(`%c ${error.response.status}  :`, 'color: red; font-weight: bold', error.response.data);
    return Promise.reject(error);
  }
);

const useCheckResponse = (error: any) => {
  switch (error.response.status) {
    case 401:
      localStorage.removeItem("token");
      localStorage.clear();
      const cookies = document.cookie.split(";");
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf("=");
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie =
          name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
      }
      window.location.reload();
      return;
      break;
    // case 403:
    //   break;
    case 404:
      console.log("error.response.message" , error.response.message);
      break;
    default:
      console.log(
        `%c ${error.response.status}  :`,
        "color: red; font-weight: bold",
        error.config
      );
      PushNotiNode(
        `/api/${getUrl(error.config)}`,
        error.response.status,
        JSON.stringify(error.response.data.message || error.response.data),
        error.config.method,
        error.config.data,
      );
  }
};
instance.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  function (error: any) {
    useCheckResponse(error);
    if (error.response) {
      return Promise.reject({
        error: error?.response,
        status: error.response.status,
        message: error.response.data.message,
      });
    } else if (error.request) {
      return Promise.reject(error.request);
    } else {
      return Promise.reject(error);
    }
  }
);
