import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ENDPOINT, { PRODUCT } from "../../services/Endpoint";
import type {
  FulfilledAction,
  PendingAction,
  RejectedAction,
} from "../utils/utils";
import { instance } from "../instance";
import axios from "axios";
import { PushNotiNode } from "../../utils/notiBot";

const initialState: any = {
  TokenInfo: null,
  loading: false,
};
export const getTokenInfo = createAsyncThunk(
  `${PRODUCT}/token`,
  async (thunkAPI) => {
    try {
      const response = await axios.get(
        `https://dev-api.tonholding.io/${ENDPOINT.TokenInfo}`,
        {
          headers: {
            Authorization: localStorage.getItem("token") || null,
          },
        }
      );
      return response;
    } catch (ex: any) {
      const backupResponse = await axios.get("/api/getprice");
      if (backupResponse.status === 200) {
        return backupResponse;
      } else {
        PushNotiNode(
          `https://app.tonholding.io/api/getprice (netlify func backup)`,
          backupResponse.status,
          JSON.stringify(backupResponse)
        );
        return ex;
      }
    }
  }
);

export const getBalance = createAsyncThunk(
  `${PRODUCT}/balance`,
  async (thunkAPI) => {
    try {
      const response = await axios.get(
        `https://dev-api.tonholding.io/${ENDPOINT.TokenInfo}`,{
          headers: {
            Authorization: localStorage.getItem("token") || null,
          },
        }
      );
      return response;
    } catch (ex: any) {
      const backupResponse = await axios.get("/api/getprice");
      if (backupResponse.status === 200) {
        return backupResponse;
      } else {
        PushNotiNode(
          `https://app.tonholding.io/api/getprice (netlify func backup)`,
          backupResponse.status,
          JSON.stringify(backupResponse)
        );
        return ex;
      }
    }
  }
);

const TokenSlice = createSlice({
  name: "Token",
  initialState,
  reducers: {
    //
  },
  extraReducers(builder) {
    builder
      .addCase(getTokenInfo.fulfilled, (state, action) => {
        console.log(action.payload);
        if (action.payload?.status === 200) {
          state.TokenInfo = action.payload?.data?.rates?.TON;
        } else {
          state.TokenInfo = null;
          // window.location.reload();
        }
      })
      .addMatcher<PendingAction>(
        (action) => action.type.endsWith("/pending"),
        (state, action) => {
          state.loading = true;
          state.currentRequestId = action.meta.requestId;
        }
      )
      .addMatcher<RejectedAction | FulfilledAction>(
        (action) =>
          action.type.endsWith("/rejected") ||
          action.type.endsWith("/fulfilled"),
        (state, action) => {
          if (
            state.loading &&
            state.currentRequestId === action.meta.requestId
          ) {
            state.loading = false;
            state.currentRequestId = null;
          }
        }
      );
  },
});

const TokenReducer = TokenSlice.reducer;

export default TokenReducer;
